import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import { compose } from "recompose";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import CustomButton, { SECONDARY } from "../ui-core/CustomButton";
import TextWithLink from "../router/TextWithLink";
import FlexBox, {
  ALIGN_CENTER,
  JUSTIFY_CENTER,
  JUSTIFY_SPACE_AROUND,
  JUSTIFY_START,
} from "../ui-core/FlexBox";
import _ from "lodash";
import { DATE, DIALOG, RENDER } from "./MUITable";
import DateTimeCell from "../data-list/DateTimeCell";
import {
  FormControl,
  LinearProgress,
  MenuItem,
  Select,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { FilterList, HighlightOff } from "@material-ui/icons";
import OrderStatusCodes from "../../constants/OrderStatusCodes";
import { filterFormPayTypes } from "../../constants/OrderPaymentTypes";
import {
  CREATED_DATE,
  PAYMENT_TYPE,
  STATUS,
} from "../../constants/marketplace/AdminCustomizableOrderListConstants";
import fp from "lodash/fp";
import { DatePicker } from "@material-ui/pickers";
import {
  DATE_SHORT_FORMAT,
  formatDateTimeToUrl,
  safeParseDate,
} from "../../helpers/FormatUtils";
import { startOfToday } from "date-fns";
import { OUTLINED } from "../form/FormTextField";
import { getUserLanguage } from "../../reducers/ProfileReducer";
import { RUSSIAN_LANGUAGE, UZBEK_LANGUAGE } from "../../constants/LocaleTypes";
import LockerPostImage from "../../assets/LockerPost-outlined.png";

export const LINK = "link";
export const COLUMN = "column";
export const ACTION = "action";

const EnhancedTableHead = ({
  getLocalisationMessage,
  columns,
  isCheckboxEnabled,
  numSelected,
  onSelectAllClick,
  rowCount,
  orderBy,
  orderByDirection,
  setOrderBy,
  filter,
  setFilter,
}) => {
  const [isFilterOpen, setIsFilterOpen] = useState({});
  return (
    <TableHead>
      <TableRow>
        {isCheckboxEnabled && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
            />
          </TableCell>
        )}
        {columns.map(v => (
          <TableCell
            key={v.slug}
            align={v.align ? v.align : "center"}
            // padding={v.disablePadding ? "none" : "normal"}
            padding="normal"
            sortDirection={orderBy === v.slug ? orderByDirection : false}
          >
            {v.withoutIcon ? (
              <span
                style={{
                  padding: ".5rem 0",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  display: "block",
                  textOverflow: "ellipsis",
                }}
              >
                {getLocalisationMessage(v.label)}
              </span>
            ) : (
              <FlexBox
                justify={v.slug !== "service" ? JUSTIFY_CENTER : JUSTIFY_START}
                align={ALIGN_CENTER}
              >
                {isFilterOpen[v.slug] ? (
                  v.slug === PAYMENT_TYPE.slug ? (
                    <FormControl
                      fullWidth={true}
                      size="small"
                      variant="outlined"
                      hiddenLabel={true}
                    >
                      <Select
                        label={getLocalisationMessage(v.label)}
                        value={filter[v.slug]}
                        onChange={e =>
                          setFilter({ ...filter, [v.slug]: e.target.value })
                        }
                      >
                        {filterFormPayTypes.map(i => (
                          <MenuItem key={i} value={i}>
                            {getLocalisationMessage(i)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : v.slug === STATUS.slug ? (
                    <FormControl
                      fullWidth={true}
                      size="small"
                      variant="outlined"
                      hiddenLabel={true}
                    >
                      <Select
                        label={getLocalisationMessage(v.label)}
                        value={filter[v.slug]}
                        onChange={e =>
                          setFilter({ ...filter, [v.slug]: e.target.value })
                        }
                      >
                        {OrderStatusCodes.map(i => (
                          <MenuItem key={i} value={i}>
                            {getLocalisationMessage(i)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : v.slug === CREATED_DATE.slug ? (
                    <DatePicker
                      style={{ minWidth: 70 }}
                      fullWidth={true}
                      hintText={getLocalisationMessage("from_date")}
                      initialTime={startOfToday()}
                      value={safeParseDate(filter.from_date_time)}
                      onChange={e =>
                        setFilter({
                          ...filter,
                          from_date_time: formatDateTimeToUrl(e),
                        })
                      }
                      format={DATE_SHORT_FORMAT}
                      onBlur={fp.noop}
                      label={getLocalisationMessage("from_date")}
                      inputVariant={OUTLINED}
                      size="small"
                      cancelLabel={
                        <CustomButton color={SECONDARY}>Cancel</CustomButton>
                      }
                      okLabel={
                        <CustomButton color={SECONDARY}>OK</CustomButton>
                      }
                    />
                  ) : null
                ) : (
                  <TableSortLabel
                    disabled={!v.isSortable}
                    active={orderBy === v.slug}
                    direction={orderBy === v.slug ? orderByDirection : "asc"}
                    onClick={() => setOrderBy(v.slug)}
                  >
                    <span
                      style={{
                        padding: ".5rem 0",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        display: "block",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {getLocalisationMessage(v.label)}
                    </span>
                  </TableSortLabel>
                )}
                {!v.withFilter ? null : isFilterOpen[v.slug] ? (
                  <HighlightOff
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setIsFilterOpen({ ...isFilterOpen, [v.slug]: false });
                      if (filter[v.slug]) {
                        const tempFilter = { ...filter };
                        delete tempFilter[v.slug];
                        setFilter(tempFilter);
                      } else if (v.slug === "created_date") {
                        const tempFilter = { ...filter };
                        delete tempFilter.from_date_time;
                        setFilter(tempFilter);
                      }
                    }}
                  />
                ) : (
                  <FilterList
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setIsFilterOpen({ ...isFilterOpen, [v.slug]: true })
                    }
                  />
                )}
              </FlexBox>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const enhancer = compose(
  connect(
    state => ({
      userLanguage: getUserLanguage(state),
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
);
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number,
  onSelectAllClick: PropTypes.func,
  rowCount: PropTypes.number,
  columns: PropTypes.array,
  isCheckboxEnabled: PropTypes.bool,
  getLocalisationMessage: PropTypes.func,
  orderBy: PropTypes.string,
  orderByDirection: PropTypes.string,
  setOrderBy: PropTypes.func,
  filter: PropTypes.func,
  setFilter: PropTypes.func,
};

const useStyles = makeStyles({
  root: {
    width: "100%",
    backgroundColor: "white",
    height: "88vh",
    position: "relative",
  },
  paper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  title: {
    paddingTop: "1rem",
    paddingLeft: "1rem",
  },
  container: {
    zIndex: 0,
    maxHeight: "calc(100vh - 15rem)",
    overflow: "overlay",
    flexGrow: "1",
  },
  link: {
    color: "#9c0008",
    textDecoration: "none",
    cursor: "pointer",
  },
  linkCustomer: {
    color: "#183E98",
    textDecoration: "none",
    cursor: "pointer",
    fontWeight: 500,
  },
  rowOdd: { important: false, backgroundColor: "white" },
  rowEven: { important: false, backgroundColor: grey[100] },
  tableRow: {
    transition: `background-color 300ms ease-out`,
    "&:hover": { backgroundColor: `${grey[200]} !important` },
  },
  unpaidOrderRow: {
    backgroundColor: `rgba(242, 104, 74, 0.4) !important`,
    "&:hover": { backgroundColor: `rgba(242, 104, 74, 0.6) !important` },
    transition: `background-color 300ms ease-out`,
  },
  longString: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 4,
    WebkitBoxOrient: "vertical",
  },
  tooltip: {
    zIndex: 5,
    flexDirection: "column",
    justifyContent: "space-between",
    display: "flex",
    transition: `all 300ms ease`,
    borderRadius: 6,
    padding: ".5rem 1rem",
    backgroundColor: "#F2684A",
    color: "white",
    position: "absolute",
    width: 250,
  },
  arrow: {
    top: ".5rem",
    position: "relative",
    "&:after": {
      zIndex: 6,
      width: 0,
      height: 0,
      borderLeft: "10px solid transparent",
      borderRight: "10px solid transparent",
      borderTop: "10px solid #F2684A",
      content: '""',
      position: "absolute",
      top: "100%",
      left: "50%",
    },
  },
});

const OrdersMUITable = ({
  columns,
  getLocalisationMessage,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  list,
  rowsPerPage,
  total,
  isCheckboxEnabled,
  headerActions,
  size,
  setOrderId,
  setIsOpenBrief,
  selectedItems,
  setSelectedItems,
  isLoading,
  orderBy,
  orderByDirection,
  setOrderBy,
  filter,
  setFilter,
  userLanguage,
}) => {
  const classes = useStyles();
  const [isShowTooltip, setIsShowTooltip] = useState(false);
  const handleSelectAllClick = event => {
    if (event.target.checked) {
      setSelectedItems(list);
      return;
    }
    setSelectedItems([]);
  };

  const handleClick = (event, row) => {
    if (event.target.checked) setSelectedItems([...selectedItems, row]);
    else setSelectedItems(selectedItems.filter(v => v.id !== row.id));
  };

  const isSelected = id => _.findIndex(selectedItems, { id }) !== -1;
  const [cursorPosition, setCursorPosition] = useState({ top: 0, left: 0 });
  const screenY =
    userLanguage === UZBEK_LANGUAGE
      ? 235
      : userLanguage === RUSSIAN_LANGUAGE
      ? 280
      : 185;
  const onMouseMove = e =>
    setCursorPosition({ top: e.clientY - screenY, left: e.clientX - 138 });
  return (
    <div onMouseMove={onMouseMove} className={classes.root}>
      <span
        className={classes.tooltip}
        style={{
          // display: "none",
          transform: `scale(${isShowTooltip ? 1 : 0})`,
          // opacity: isShowTooltip ? 1 : 0,
          ...cursorPosition,
        }}
      >
        <span style={{ textAlign: "center" }}>
          {getLocalisationMessage("its_unpaid_order")}
        </span>
        <span className={classes.arrow} />
      </span>
      <Paper className={classes.paper}>
        <FlexBox
          flex={true}
          justify={JUSTIFY_SPACE_AROUND}
          align={ALIGN_CENTER}
          style={{
            flexGrow: 0,
          }}
        >
          {headerActions}
        </FlexBox>
        <TableContainer className={classes.container}>
          <Table
            stickyHeader={true}
            className={classes.table}
            aria-labelledby="tableTitle"
            size={size}
            aria-label="sticky table"
          >
            <EnhancedTableHead
              filter={filter}
              setFilter={setFilter}
              setOrderBy={setOrderBy}
              orderBy={orderBy}
              orderByDirection={orderByDirection}
              classes={classes}
              selectedItems={selectedItems}
              numSelected={selectedItems.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={list.length}
              columns={columns}
              isCheckboxEnabled={isCheckboxEnabled}
              getLocalisationMessage={getLocalisationMessage}
            />
            {isLoading ? (
              <LinearProgress
                style={{ position: "absolute", width: "99.6%" }}
                mode="indeterminate"
                color="secondary"
              />
            ) : (
              <TableBody onMouseOut={() => setIsShowTooltip(false)}>
                {list.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const isUnpaidOrder = !_.get(row, "payment_status");
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const isLockerPostOrder = _.get(row, "is_locker_post_order");

                  return (
                    <TableRow
                      // onClick={(event) =>
                      //   isCheckboxEnabled && handleClick(event, row.id)
                      // }
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      className={`${classes.tableRow} ${
                        isUnpaidOrder
                          ? classes.unpaidOrderRow
                          : index % 2 === 0
                          ? classes.rowEven
                          : classes.rowOdd
                      }`}
                      hover={true}
                      onMouseOver={() => {
                        if (isUnpaidOrder) setIsShowTooltip(true);
                        else setIsShowTooltip(false);
                      }}
                      // onMouseOut={() => setIsShowTooltip(false)}
                    >
                      {isCheckboxEnabled && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                            onChange={event => handleClick(event, row)}
                          />
                        </TableCell>
                      )}
                      {columns.map((item, i) =>
                        i === 0 ? (
                          <TableCell
                            key={i}
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            align="center"
                          >
                            {item.type === LINK ? (
                              <TextWithLink to={item.link + row[item.keyName]}>
                                {row[item.keyName]}
                              </TextWithLink>
                            ) : item.type === DIALOG ? (
                              // eslint-disable-next-line
                              <span
                                className={
                                  item.isCustomer
                                    ? classes.linkCustomer
                                    : classes.link
                                }
                                onClick={() => {
                                  setOrderId(row.id);
                                  setIsOpenBrief(true);
                                }}
                              >
                                <FlexBox justify="space-around">
                                  <span> {row[item.keyName]} </span>
                                  {isLockerPostOrder && (
                                    // eslint-disable-next-line jsx-a11y/alt-text
                                    <img
                                      style={{ width: 20, height: 20 }}
                                      src={LockerPostImage}
                                    />
                                  )}
                                </FlexBox>
                              </span>
                            ) : (
                              row[item.keyName]
                            )}
                          </TableCell>
                        ) : item.type === COLUMN ? (
                          <TableCell
                            // className={item.longString && classes.longString}
                            align="center"
                            key={i}
                            padding="none"
                          >
                            {item.withTranslation
                              ? getLocalisationMessage(_.get(row, item.keyName))
                              : item.withRender
                              ? item.render(row)
                              : _.get(row, item.keyName)}
                          </TableCell>
                        ) : item.type === RENDER ? (
                          <TableCell align="center" key={i} padding="none">
                            {item.render(row, getLocalisationMessage)}
                          </TableCell>
                        ) : item.type === DATE ? (
                          <TableCell align="center" key={i} padding="none">
                            <DateTimeCell date={_.get(row, item.keyName)} />
                          </TableCell>
                        ) : (
                          <TableCell align="center" key={i} padding="none">
                            <CustomButton onClick={() => item.dispatch(row.id)}>
                              {item.label}
                            </CustomButton>
                          </TableCell>
                        ),
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
          <TablePagination
            style={{
              position: "fixed",
              background: "white",
              bottom: 0,
              width: "100vw",
              borderTop: "1px solid rgba(0, 0, 0, 0.3)",
            }}
            labelRowsPerPage={getLocalisationMessage("rows_per_page")}
            rowsPerPageOptions={[20, 50]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Paper>
    </div>
  );
};

OrdersMUITable.propTypes = {
  getLocalisationMessage: PropTypes.func,
  list: PropTypes.array,
  columns: PropTypes.array,
  total: PropTypes.number,
  isCheckboxEnabled: PropTypes.bool,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  headerActions: PropTypes.any,
  setOrderId: PropTypes.func,
  setIsOpenBrief: PropTypes.func,
  selectedItems: PropTypes.array,
  setSelectedItems: PropTypes.func,
  size: PropTypes.oneOf(["small", "medium"]),
  isLoading: PropTypes.bool,
  orderBy: PropTypes.string,
  orderByDirection: PropTypes.string,
  setOrderBy: PropTypes.func,
  filter: PropTypes.func,
  setFilter: PropTypes.func,
  userLanguage: PropTypes.func,
};

export default enhancer(OrdersMUITable);
