import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getMessage } from "../../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../reducers/NotificationsReducer";
import { connect } from "react-redux";
import AdminAppLayout from "../../../components/admin/AdminAppLayout";
import { getSortingShipmentSimple } from "../../../api/v2/admin/AdminOrderSortingApi";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../../api/admin/AdminSupplierApi";
import {
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../../api/admin/AdminWarehouseApi";
import {
  getCachedPostcode,
  getPostcodePredictions,
} from "../../../api/shared/CountryV2Api";
import OfflineOrderSortingRuleListDialog from "../../../components/order-outbound-sorting/OfflineOrderSortingRuleListDialog";
import { compose, getContext } from "recompose";
import { getUserWarehouse } from "../../../reducers/ProfileReducer";
import { Map } from "immutable";
import AdminOrderOfflineOutboundRegistrySortingTableContainer from "./AdminOrderOfflineOutboundRegistrySortingTableContainer";
import { getValue } from "../../../helpers/DataUtils";
import FlexBox from "../../../components/ui-core/FlexBox";
import CustomButton, {
  CONTAINED,
  SECONDARY,
} from "../../../components/ui-core/CustomButton";
import SearchBatchItemDialog from "../../../components/batch-updates-dialog/SearchBatchItemDialog";
import { SIMPLE } from "../../../constants/OrderType";

function AdminOrderOfflineOutboundRegistrySortingContainer(props) {
  const { getLocalisationMessage, onTabChange, tabCount } = props;
  const [sortingItems, setSortingItems] = useState([]);
  const [sortingServiceTypes, setSortingServiceTypes] = useState([]);
  const [sortingBeans, setSortingBeans] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [excludeSelectedItems, setExcludeSelectedItems] = useState([]);
  const [showBinDialog, setShowBinDialog] = useState(false);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState({
    init: true,
    page: 0,
    size: 20,
    service_type_id: null,
    beans: [],
  });

  const [selectBin, setSelectBin] = useState([]);
  const [selectFilterBin, setSelectFilterBin] = useState([]);

  const onServiceTypeFilter = value => {
    setSortingBeans([]);
    setSortingItems([]);
    setSelectedItems([]);
    setSelectBin([]);
    setSelectFilterBin([]);
    setExcludeSelectedItems([]);

    setFilter(prevState => {
      const newObj = {
        ...prevState,
        page: 0,
        init: true,
        service_type_id: value,
        beans: [],
      };
      return newObj;
    });
  };

  const onBeanFilter = bean => {
    setSelectedItems([]);
    setSortingItems([]);
    setExcludeSelectedItems([]);
    setSelectFilterBin(prev =>
      filter.beans && filter.beans.length === 0 ? [bean] : [...prev, bean],
    );
    setSelectBin([]);

    setFilter(prevState => {
      let newObj = {};
      newObj = {
        ...prevState,
        init: false,
        page: 0,
        size: 500,
        beans: [...prevState.beans, bean],
      };

      delete newObj.barcodes;

      return newObj;
    });
  };

  const onBeanUnFilter = bean => {
    setSelectedItems([]);
    setSortingItems([]);
    setSelectedItems(prev =>
      prev.filter(
        item => getValue(item, "bean.code") !== getValue(bean, "code"),
      ),
    );

    setSelectFilterBin(prev => {
      const idx = prev.findIndex(
        i => getValue(i, "code") === getValue(bean, "code"),
      );
      return [...prev.slice(0, idx), ...prev.slice(idx + 1)];
    });

    setSelectBin([]);

    setFilter(prevState => {
      const idx = prevState.beans.findIndex(
        i => getValue(i, "code") === getValue(bean, "code"),
      );

      const newObj = {
        ...prevState,
        init: false,
        page: 0,
        size:
          prevState.beans.filter(
            item => getValue(item, "code") !== getValue(bean, "code"),
          ).length > 0
            ? 500
            : 20,
        beans: [
          ...prevState.beans.slice(0, idx),
          ...prevState.beans.slice(idx + 1),
        ],
      };

      return newObj;
    });
  };

  const refreshOrderList = () =>
    setFilter(prevState => {
      const newObj = {
        ...prevState,
        init: true,
        barcodes: [],
        refresh: !filter.refresh,
      };

      return newObj;
    });

  const handleChangePage = (event, page) => {
    setFilter({ ...filter, page });
    setSelectedItems([]);
  };
  const handleChangeRowsPerPage = event => {
    setFilter({ ...filter, size: parseInt(event.target.value, 10), page: 0 });
    setSelectedItems([]);
  };

  useEffect(() => {
    setIsLoading(true);
    getSortingShipmentSimple(filter)
      .then(res => {
        if (res) {
          setSortingItems(res.items);
          if (res.beans) {
            setSortingBeans(res.beans);
          }
          if (res.services) {
            setSortingServiceTypes([
              {
                id: null,
                code: "all",
                name: "All",
                count: res.total,
              },
              ...res.services,
            ]);
          }
          setTotal(res.total);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch(() => setIsLoading(false));
  }, [filter]);

  const [openSearchDialog, setOpenSearchDialog] = useState(false);

  return (
    <AdminAppLayout
      title={`${getLocalisationMessage(
        "order_registry_sorting",
        "Order Registry Sorting",
      )} | ${props.userWarehouse.get("name")}`}
      appBarRightAction={
        <FlexBox direction="row" align="center" justify="flex-end">
          <CustomButton
            variant={CONTAINED}
            color={SECONDARY}
            onClick={() => setOpenSearchDialog(true)}
          >
            {getLocalisationMessage("search_simple", "Search Simple")}
          </CustomButton>
        </FlexBox>
      }
    >
      <SearchBatchItemDialog
        type={SIMPLE}
        open={openSearchDialog}
        onRequestClose={() => setOpenSearchDialog(false)}
      />

      {showBinDialog && (
        <OfflineOrderSortingRuleListDialog
          open={showBinDialog}
          onRequestClose={() => setShowBinDialog(false)}
          onSubmitSuccess={() => setShowBinDialog(false)}
          onSubmitFail={props.showErrorMessage}
          getCachedSupplier={getCachedSupplier}
          getSupplierPredictions={getSupplierPredictions}
          getCachedWarehouse={getCachedWarehouse}
          getWarehousePredictions={getWarehousePredictions}
          getCachedPostcode={getCachedPostcode}
          getPostcodePredictions={getPostcodePredictions}
        />
      )}

      <AdminOrderOfflineOutboundRegistrySortingTableContainer
        selectFilterBin={selectFilterBin}
        setSelectFilterBin={setSelectFilterBin}
        onTabChange={onTabChange}
        setRefreshTab={props.setRefreshTab}
        tabCount={tabCount}
        tabValue={filter.type}
        sortingItems={sortingItems}
        sortingServiceTypes={sortingServiceTypes}
        sortingBeans={sortingBeans}
        total={total}
        refreshOrderList={refreshOrderList}
        onServiceTypeFilter={onServiceTypeFilter}
        onBeanFilter={onBeanFilter}
        onBeanUnFilter={onBeanUnFilter}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        filter={filter}
        isLoading={isLoading}
        selectedItems={selectedItems}
        setFilter={setFilter}
        setSelectedItems={setSelectedItems}
        excludeSelectedItems={excludeSelectedItems}
        setExcludeSelectedItems={setExcludeSelectedItems}
        selectBin={selectBin}
        setSelectBin={setSelectBin}
      />
    </AdminAppLayout>
  );
}

AdminOrderOfflineOutboundRegistrySortingContainer.propTypes = {
  userWarehouse: PropTypes.instanceOf(Map),
  showErrorMessage: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  setRefreshTab: PropTypes.func,
  onTabChange: PropTypes.func,
  tabCount: PropTypes.string,
};

const mapStateToProps = state => ({
  userWarehouse: getUserWarehouse(state),
  getLocalisationMessage: (code, defaultMessage) =>
    getMessage(state, code, defaultMessage),
});

const mapDispatchToProps = { showErrorMessage, showSuccessMessage };

const enhancer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  getContext({
    setLocationQueryFilter: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
    setLocation: PropTypes.func.isRequired,
  }),
);

export default enhancer(AdminOrderOfflineOutboundRegistrySortingContainer);
